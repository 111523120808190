import getRoute from "~/utilities/configs/apiMap";
import { unitPageStore } from "~/store/unitPage";

export default defineNuxtRouteMiddleware(async (to) => {
  const { id } = to.params;
  let { type } = to.query;

  if (!id) {
    return navigateTo("/404");
  }

  const useUnitpageStore = unitPageStore();
  const currentType = () => {
    switch (type) {
      case "estate":
        return "unit-page-estate-unit";
      case "land":
        return "unit-page-land-unit";
      default:
        return "";
    }
  };

  const checkType = async (queryType: string) => {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    const setUrl =
      baseUrl + getRoute({ endpont: `get_${queryType}`, id: id as string });

    const response = await useFetch(setUrl, {
      method: "HEAD",
      headers: {
        Authorization: `Bearer ${useCookie("acc_token").value}`,
      },
    });

    return response.status.value === "success";
  };

  try {
    if (!type) {
      // If type is not present, check both 'land' and 'estate'
      const estateCheck = await checkType("estate");
      const landCheck = await checkType("land");

      if (!estateCheck && !landCheck) {
        return navigateTo("/404");
      }

      // Set type based on which request was successful
      type = estateCheck ? "estate" : "land";
    } else {
      const validTypeCheck = await checkType(type as string);
      if (!validTypeCheck) {
        return navigateTo("/404");
      }
    }

    // Update the store with the valid type
    useUnitpageStore.$patch({
      currentElementInstance: currentType(),
    });
  } catch (error) {
    return navigateTo("/404");
  }
});
